import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import DialogTip from '@/components/PlugDialog/instance'
import store from '@/store'
import { getToken } from '@/utils/auth'
import { tansParams } from '@/utils/validate'
import i18n from '@/lang'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 60000
})

// request拦截器
service.interceptors.request.use(
  (config) => {
    // 是否需要设置 token
    if (store.getters.token) {
      config.headers['Authorization'] = 'Bearer ' + getToken()
    }
    config.headers['Accept-Language'] = 'en'
    // get请求映射params参数
    if (config.method === 'get' && config.params) {
      let url = config.url + '?' + tansParams(config.params)
      url = url.slice(0, -1)
      config.params = {}
      config.url = url
    }

    return config
  },
  (error) => {
    console.log(error)
    Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200

    // 二进制数据则直接返回
    if (
      res.request.responseType === 'blob' ||
      res.request.responseType === 'arraybuffer'
    ) {
      return res.data
    }
    if (code == 200) {
      return res.data
    } else {
      store.dispatch('setDialogConfirmBtn', false)
      if (code === 401) {
        DialogTip()
        return Promise.reject('error')
      } else if (code == 400 || code == 403 || code == 300) {
        Message({
          message: res.data ? res.data.message : res.message,
          type: 'error',
          duration: 2 * 1000
        })
        return Promise.reject(res.data)
      } else if (code !== 200) {
        // Message({
        //   message: res.data ? res.data.message : res.message,
        //   type: 'error',
        //   duration: 2 * 1000
        // })
        return Promise.reject(res.data)
      }
    }
  },
  (error) => {
    let { message } = error

    if (message == 'Network Error') {
      message = i18n.t('errorCode.500')
    } else if (message.includes('timeout')) {
      message = i18n.t('errorCode.timeout')
    }
    Message({
      message: message,
      type: 'error',
      duration: 5 * 1000
    })
    store.dispatch('setDialogConfirmBtn', false)
    return Promise.reject(error)
  }
)

export default service
